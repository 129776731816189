export default (theme) => ({
	faqQuestion: {
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 0,
		marginTop: 0,
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
		},
	},
	faqAnswer: {
		fontSize: 16,
		lineHeight: 1.6,
		color: theme.palette.grey[700],
		[theme.breakpoints.down("xs")]: {
			fontSize: 14,
		},
	},
	faqWrapper: {
		"& ul": {
			marginBottom: 24,
		},
		"& ol": {
			marginBottom: 24,
		},
		"& li": {
			marginBottom: 4,
		},
		"& li li": {
			marginBottom: 4,
		},
		"& li p": {
			margin: 0,
			fontSize: "inherit",
			lineHeight: "inherit",
		},
		"& li ul": {
			marginTop: 12,
			marginBottom: 18,
		},
		"& p": {
			"&:last-child": {
				margin: 0,
			},
		},
		"& a": {
			color: theme.palette.primary.main,
		},
		"& a:hover": {
			textDecoration: "underline",
		},
		"& strong": {
			fontWeight: 600,
		},
		"& b": {
			fontWeight: 600,
		},
		"& blockquote": {
			"lineHeight": 1.6,
			"padding": 24,
			"background": "#effffa",
			"borderLeft": "5px solid #53a288",
			"margin": "0px 0px 32px",
			"borderRadius": 8,
			"fontStyle": "italic",
			"& p": {
				margin: "0 !important",
			},
		},
		"& code": {
			backgroundColor: "rgb(20 237 167 / 10%)",
			padding: 4,
			borderRadius: 8,
		},
	},
	accordionRoot: {
		"boxShadow": "unset",
		"borderBottom": "1px solid #dddddd",
		"&:before": {
			height: 0,
		},
		"&:last-child": {
			borderBottom: 0,
		},
	},
	accordionSRoot: {
		padding: 0,
	},
	accordionSContent: {
		margin: "24px 0px",
	},
	accordionDetailsRoot: {
		padding: "0px 0px 36px",
	},
});
