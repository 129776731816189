import { observable, computed, action, toJS, reaction } from "mobx";
import { replaceUrlParam } from "@utils/Browser";

class SearchPanelStore {
	service;
	midtail;

	@observable selectedServiceKey;
	@observable zipcode;
	@observable providers = [];
	@observable selectServiceHasError = false;

	@observable initialized = false; //needed to reload component at mount time so that shared component's stores are within context of this component

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
		this.service = staticContext.service;
		this.midtail = staticContext.midtail;
		this.zipcode = staticContext.app.location.zipcode;
		this.providers = staticContext.providers || [];
	}

	get showSelectService() {
		return !!this.midtail && !this.service;
	}

	get leadFunnelModalStore() {
		const { leadFunnelModalStore } = this.rootStore.stores;
		return leadFunnelModalStore;
	}

	get leadFunnelIsShowing() {
		return this.leadFunnelModalStore?.show;
	}

	get searchUrl() {
		const { quickPickQuestionStore } = this.rootStore.stores;
		let url = "/search";
		if (this.service?.search_to_map) url = "/search-map";
		if (this.service?.midtail || this.midtail)
			url = replaceUrlParam(
				url,
				"midtail",
				this.service?.midtail ? this.service.key : this.midtail.key
			);
		if (this.service && !this.service.midtail)
			url = replaceUrlParam(url, "service", this.service.key);
		if (this.selectedServiceKey)
			url = replaceUrlParam(url, "service", this.selectedServiceKey);

		if (quickPickQuestionStore) {
			url = replaceUrlParam(
				url,
				"answers",
				quickPickQuestionStore.encodedSelection
			);
		}

		if (this.zipcode) {
			url = replaceUrlParam(url, "zipcode", this.zipcode);
		}

		return url;
	}

	@action
	updateZipcode(value) {
		this.zipcode = value;
	}

	@action
	updateSelectedServiceKey(val) {
		this.selectedServiceKey = val;
		if (this.selectedServiceKey) this.selectServiceHasError = false;
	}

	@action
	redirectToSearchPage(zipcodeTextBoxId) {
		const { textFieldStore } = this.rootStore.stores;
		if (textFieldStore.isValid(zipcodeTextBoxId) === false) {
			throw new Error("Invalid zipcode");
		}
		// if (!this.selectedServiceKey && this.showSelectService) {
		// 	console.log("invalid service");
		// 	throw new Error("Invalid service");

		// 	this.selectServiceHasError = true;
		// 	console.log("invalid service");
		// 	return;
		// }
		//console.log("redirectTo", this.searchUrl);
		window.location.href = this.searchUrl;
	}
}

export default SearchPanelStore;
