import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let VerifiedIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 24 24"}>
		<g>
			<path
				d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM10.09 16.72L6.29 12.91L7.77 11.43L10.09 13.76L15.94 7.89L17.42 9.37L10.09 16.72Z"
				fill="#4E93F3"
			/>
		</g>
	</SvgIcon>
);

VerifiedIcon = memo(VerifiedIcon);
VerifiedIcon.displayName = "VerifiedIcon";
VerifiedIcon.muiName = "VerifiedIcon";

export default VerifiedIcon;
