export default (theme) => ({
	searchPanelH1: {
		fontSize: 32,
		fontWeight: 700,
		lineHeight: 1.2,
		margin: 0,
	},
	searchPanelSubHeader: {
		fontSize: 16,
		lineHeight: 1.5,
		color: theme.palette.grey[700],
	},
	zipAdornment: {
		color: theme.palette.grey[700],
		fontWeight: "600",
		//fontSize: 15,
	},
	zipInput: {
		fontWeight: 600,
	},
	zipDivider: {
		height: "28px",
		margin: "0px 8px 0px 16px",
	},
	searchPanelButton: {
		height: 55,
	},
	selectBox: {
		marginBottom: 12,
		width: "100%",
	},
});
