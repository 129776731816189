import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import shortid from "shortid";

import withConstants from "@framework/src/libs/withConstants";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { constants } from "./.config";
import Link from "@material-ui/core/Link";

import NearByJobs from "@components/Main/Shared/NearByJobs";

const CoursesAndGuides = compose(withConstants(constants))(
	({ service, related_services = [], CoursesAndGuidesViewModel }) => {
		const items = (
			CoursesAndGuidesViewModel?.mapping[service.name]?.relations || []
		).map((relation) => CoursesAndGuidesViewModel.services[relation]);

		return (
			<React.Fragment>
				<Box component="section" bgcolor="grey.50" pt={1} pb={10} px={4}>
					<Container maxWidth="md">
						{related_services.length !== 0 && (
							<Box mb={14}>
								<Box fontSize={20} component="h3" fontWeight={600} mb={8}>
									{`Related services near you`}
								</Box>

								<Grid container spacing={4}>
									{related_services.map((relatedService) => (
										<Grid item xs={12} sm={6} md={4} key={shortid.generate()}>
											<Link color={"secondary"} href={relatedService.url}>
												{relatedService.phrase.ucFirst()}
											</Link>
										</Grid>
									))}
									<Grid item xs={12} sm={6} md={4}>
										<NearByJobs service={service} />
									</Grid>
								</Grid>
							</Box>
						)}

						{items.length > 0 && (
							<Box mb={14}>
								<Box fontSize={20} component="h3" fontWeight={600} mb={8}>
									Related cost guides
								</Box>

								<Grid container spacing={4}>
									{items.length &&
										items.filter(Boolean).map((item) => (
											<Grid item xs={12} sm={6} md={4} key={shortid.generate()}>
												<Link color={"secondary"} href={item.link}>
													{item.title}
												</Link>
											</Grid>
										))}
								</Grid>
							</Box>
						)}
					</Container>
				</Box>
			</React.Fragment>
		);
	}
);

CoursesAndGuides.propTypes = {
	service: PropTypes.shape({
		name: PropTypes.string.isRequired,
		// affinities: PropTypes.arrayOf(
		// 	PropTypes.shape({
		// 		affinity: PropTypes.shape({
		// 			slug: PropTypes.string.isRequired,
		// 			phrase: PropTypes.string.isRequired,
		// 		}),
		// 	})
		// ).isRequired,
	}).isRequired,
};

export default CoursesAndGuides;
