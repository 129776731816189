import React from "react";
import PropTypes from "prop-types";

import withConstants from "@framework/src/libs/withConstants";
import useConstants from "@hooks/useConstants";

import { adjective as proAdjective } from "@language/provider";

import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { constants } from "./.config";

const NearbyJobs = withConstants(constants)(({ service }) => {
	const { NearByJobsViewModel } = useConstants();
	const { title, link } = NearByJobsViewModel[service.name] || {};

	return (
		<React.Fragment>
			{link && title && (
				<Link color={"secondary"} href={link}>
					{title.ucFirst()}
				</Link>
			)}
		</React.Fragment>
	);
});

NearbyJobs.propTypes = {
	service: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
};

export default NearbyJobs;
