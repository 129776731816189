import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { compose } from "recompose";
import { useParams } from "react-router";

import { observer } from "mobx-react";
import withStores from "@framework/src/libs/withStores";
import useStores from "@hooks/useStores";
import withStyles from "@framework/src/libs/withStyles";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";

import { ZipcodeTextField } from "@components/Global/Custom/TextField";

import QuickPickQuestion from "../QuickPickQuestion";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";

import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import Select from "@components/Global/Custom/Select";

import { stores, styles } from "./.config";

import { adjective as proAdjective } from "@language/provider";

const { SITE_NAME } = process.env;

const SearchPanel = compose(
	withStores(stores),
	withStyles(styles),
	observer
)(({ id, h1, subheader, quickPickQuestions = [], onError }) => {
	const classes = useStyles("SearchPanel");
	const { searchPanelStore: store, leadFunnelModalStore } = useStores();
	const searchOnClickHandler = (id) => () => {
		try {
			store.redirectToSearchPage(id);
		} catch (e) {
			onError && onError(e);
		}
	};
	const zipOnChangeHandler = (e) => store.updateZipcode(e.target.value);
	const selectServiceOnChangeHandler = (e) =>
		store.updateSelectedServiceKey(e.target.value);

	useEffect(() => {
		const listener = (event) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				event.preventDefault();
				try {
					searchOnClickHandler(id)();
				} catch (e) {
					onError && onError(e);
				}
			}
		};
		if (store.leadFunnelIsShowing) {
			document.removeEventListener("keydown", listener);
		} else {
			document.addEventListener("keydown", listener);
		}
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, [leadFunnelModalStore?.show]);

	const goOnClickHandler = () => () => {
		if (store.leadFunnelModalStore) {
			store.leadFunnelModalStore.leadActionType = "requested_quote";
			store.leadFunnelModalStore.show = true;
		}
	};

	return (
		<React.Fragment>
			<Box display={{ xs: "none", sm: "block" }} mb={3} mt={0}>
				<h1 className={classes.searchPanelH1}>{h1}</h1>
			</Box>

			<Box mb={5}>
				<div className={classes.searchPanelSubHeader}>{subheader}</div>
			</Box>

			<div className={classes.selectBox}>
				<ZipcodeTextField
					fullWidth
					//focused={!store.zipcode}
					variant={"outlined"}
					defaultValue={store.zipcode}
					onChange={zipOnChangeHandler}
					name={`zipcode-${id}`}
					id={id}
					InputProps={{
						classes: { adornedStart: classes.zipAdornment },
						startAdornment: (
							<InputAdornment position="start">
								<RoomOutlinedIcon />
								<Box ml={2}>Zip code</Box>
								<Divider
									orientation="vertical"
									className={classes.zipDivider}
								/>
							</InputAdornment>
						),
						className: classes.zipInput,
					}}
				/>
			</div>

			{store.showSelectService && (
				<div className={classes.selectBox}>
					<Select
						error={store.selectServiceHasError}
						value={store.selectedServiceKey}
						onChange={selectServiceOnChangeHandler}
					>
						<option value={""}>Choose a service</option>
						{store.midtail.affinities.map(({ affinity }) => (
							<option key={affinity.key} value={affinity.key}>
								{affinity.name_proper
									? affinity.name_proper.ucFirst()
									: affinity.name.ucFirst()}
							</option>
						))}
					</Select>
				</div>
			)}

			{store.service &&
				quickPickQuestions.map((question, i) => (
					<QuickPickQuestion key={`quickPick-${i}`} question={question} />
				))}
			{/* {store.initialized && <div hidden={true} />} */}
			<Box pt={2}>
				{["homeguide", "fash", "tutors"].includes(SITE_NAME) ? (
					<>
						<Button
							onClick={goOnClickHandler(id)}
							fullWidth
							disableElevation
							variant="contained"
							color="primary"
							size="large"
							className={classes.searchPanelButton}
						>
							Get started
						</Button>
						<Box mt={4}>
							<Button
								onClick={searchOnClickHandler(id)}
								fullWidth
								disableElevation
								variant="outlined"
								color="primary"
								size="large"
								className={classes.searchPanelButton}
								startIcon={<SearchIcon />}
							>
								Search
							</Button>
						</Box>
					</>
				) : (
					<Button
						onClick={searchOnClickHandler(id)}
						fullWidth
						disableElevation
						variant="contained"
						color="primary"
						size="large"
						className={classes.searchPanelButton}
						startIcon={<SearchIcon />}
					>
						Search
					</Button>
				)}
			</Box>
		</React.Fragment>
	);
});

SearchPanel.defaultProps = {
	quickPickQuestions: [],
};

SearchPanel.propTypes = {
	service: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	id: PropTypes.string,
	zipcode: PropTypes.string,
	h1: PropTypes.string,
	subheader: PropTypes.string,
	quickPickQuestions: PropTypes.array,
};

export default React.memo(SearchPanel);
