import React from "react";
import PropTypes from "prop-types";

import useStyles from "@hooks/useStyles";
import { compose } from "recompose";
import withStyles from "@framework/src/libs/withStyles";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SchemaOrg from "@components/Global/Custom/SchemaOrg";
import schema from "./@data/schema.org";
// import withConstants from "@framework/src/libs/withConstants";
// import { constants } from "./.config";
import { styles } from "./.config";
//https://developers.google.com/search/docs/data-types/faqpage

const { BRAND_NAME, SITE_NAME } = process.env;

const FAQ = compose(
	withStyles(styles)
	// withConstants(constants)
)(({ service, city, FAQViewModel }) => {
	// if (!FAQViewModel?.service[service.name]) return null;
	if (!service?.faq?.length) return null;
	const items = service.faq;
	const classes = useStyles("FAQ");

	return (
		<React.Fragment>
			<Box component="section" pt={8} pb={12} pr={2} pl={2}>
				<Container maxWidth={false} style={{ maxWidth: 800 }}>
					<Paper variant={"outlined"}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							p={{ xs: 8, sm: "72px 40px" }}
						>
							<Box maxWidth={640}>
								<Box mb={11}>
									<Box
										component="h2"
										fontSize={{ xs: 26, sm: 32 }}
										fontWeight={600}
										lineHeight={1.2}
										mt={0}
										mb={0}
									>
										{`Your ${
											service.name_proper || service.name
										} questions, answered`}
									</Box>
									<Box color={"grey.700"} mt={2} lineHeight={1.5}>
										{`Answers to commonly asked questions from the experts on ${BRAND_NAME}.`}
									</Box>
								</Box>

								<div className={classes.faqWrapper}>
									{items.map((section, index) => (
										<Accordion
											classes={{ root: classes.accordionRoot }}
											key={index}
											expanded
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												id={`FAQ-${index}`}
												classes={{
													root: classes.accordionSRoot,
													content: classes.accordionSContent,
												}}
											>
												<h3 className={classes.faqQuestion}>
													{section.question}
												</h3>
											</AccordionSummary>
											<AccordionDetails
												classes={{ root: classes.accordionDetailsRoot }}
											>
												<div className={classes.faqAnswer}>
													<div
														dangerouslySetInnerHTML={{
															__html: section.answer,
														}}
													/>
												</div>
											</AccordionDetails>
										</Accordion>
									))}
								</div>
							</Box>
						</Box>
					</Paper>
				</Container>
			</Box>

			<SchemaOrg schema={schema({ items })} />
		</React.Fragment>
	);
});

FAQ.propTypes = {};
export default FAQ;
