export default ({ items }) => {
	const mainEntity = items.map((item) => {
		return {
			"@type": "Question",
			"name": item.question,
			"acceptedAnswer": {
				"@type": "Answer",
				"text": item.answer,
				// "text": item.answer.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")
			},
		};
	});
	return {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": mainEntity,
	};
};
